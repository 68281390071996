/**
 * Created by caiosilva on 26/10/15.
 */
var base_url = function (segment) {

    return "//" + window.location.host + "/" + segment;

};
var starRating = function () {
    $.fn.raty.defaults.hints=['Precisa Melhorar','Regular','Bom','Ótimo','Excelente'];
    var ratyElement = $('.raty');
    var size = (ratyElement.data('size')) ? ratyElement.data('size') + ' ' : '';
    ratyElement.raty({
        score: function () {
            return $(this).attr('data-score');
        },
        space: false,
        readOnly: true,
        starType: 'i',
        starOff: size + 'orange  in star empty icon',
        starHalf: size + 'orange transition flash slide right in in star half empty icon',
        starOn: size + 'orange transition flash slide right in star icon'
    });
};
starRating();
var jsArray = {};
var cssArray = {};
//Remove o Hash do Facebook
if (window.location.hash && window.location.hash == '#_=_') {
    window.location.hash = '';
}

//$(document).ready(function () {

    var mobileMenu = $('#mobile-menu');
    var mobileMenuToggleButton = $('#toggle-mobile-menu');

    mobileMenuToggleButton.click(function () {
       mobileMenu.slideToggle(200);
    });

    var main_menu = $('#main-menu');
    main_menu.visibility({
        type: 'fixed',
        once: true,
        //offset: 0,
        onBottomPassed: function () {
            main_menu.css('z-index', '10');
            main_menu.animate({height: 42}, 200);
            $('#main-logo').show();
            $('#main-logo').transition('slide up in');
        },
        onBottomPassedReverse: function () {
            main_menu.animate({height: 80}, 200);
            $('#main-logo').transition('slide up out');
            $('#main-logo').hide();
        }
    });

    $('.dropdown').dropdown();

    $('#mobile-menu.ui.sidebar').sidebar({
        //context: '#fake-body',
        //transition: 'overlay'
    }).sidebar('attach events', '.toc.item');

    /* == ScrollUp == */
    $(window).scroll(function () {
        if ($(this).scrollTop() > 100) { //use `this`, not `document`
            $('#scroll-up').slideDown();

        } else {
            $('#scroll-up').slideUp();
        }
    });

    $('#scroll-up').click(function () {
        $('body,html').animate({scrollTop: 0}, 200);
    });



//});


$('.dropdown .search').dropdown({
    message: {
        addResult: 'Adicionar <b>{term}</b>',
        count: '{count} selecionado',
        maxSelections: 'Max {maxCount} selections',
        noResults: 'Sem resultados.'
    }
});

function loadjscssfile(filename, filetype) {
    if (filetype == "js") { //if filename is a external JavaScript file
        var fileref = document.createElement('script')
        fileref.setAttribute("type", "text/javascript")
        fileref.setAttribute("src", filename)
    }
    else if (filetype == "css") { //if filename is an external CSS file
        var fileref = document.createElement("link")
        fileref.setAttribute("rel", "stylesheet")
        fileref.setAttribute("type", "text/css")
        fileref.setAttribute("href", filename)
    }
    if (typeof fileref != "undefined")
        document.getElementsByTagName("head")[0].appendChild(fileref)
}

function loadScript(scriptName, callback) {

    if (!jsArray[scriptName]) {
        jsArray[scriptName] = true;

        // adding the script tag to the head as suggested before
        var body = document.getElementsByTagName('body')[0],
            script = document.createElement('script');
        script.type = 'text/javascript';
        script.src = scriptName;

        // then bind the event to the callback function
        // there are several events for cross browser compatibility
        script.onload = callback;

        // fire the loading
        body.appendChild(script);

        // clear DOM reference
        //body = null;
        //script = null;

    } else if (callback) {
        // changed else to else if(callback)
        //execute function
        callback();
    }

}

function loadCss(scriptName, callback) {

    if (!cssArray[scriptName]) {
        cssArray[scriptName] = true;

        // adding the script tag to the head as suggested before
        var head = document.getElementsByTagName('head')[0],
            link = document.createElement('link');
        link.type = 'text/css';
        link.rel = 'stylesheet';
        link.href = scriptName;

        // then bind the event to the callback function
        // there are several events for cross browser compatibility
        link.onload = callback;

        // fire the loading
        head.appendChild(link);

        // clear DOM reference
        //body = null;
        //script = null;

    } else if (callback) {
        // changed else to else if(callback)
        //execute function
        callback();
    }

}
function removeScript(callback) {
    $('body script').each(function () {
        var scriptSrc = $(this).attr("src");
        if (jsArray[scriptSrc]) {
            jsArray[scriptSrc] = false;
            $(this).remove();
        }
    });
    jsArray = {};
    if (callback) {
        callback();
    }
}

//$('body .sp-container').each(function () {
//    $(this).remove();
//});