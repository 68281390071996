
$.fn.serializeObject = function () {
    var o = {};
    var a = this.serializeArray();
    $.each(a, function () {
        if (o[this.name] !== undefined) {
            if (!o[this.name].push) {
                o[this.name] = [o[this.name]];
            }
            o[this.name].push(this.value || '');
        } else {
            o[this.name] = this.value || '';
        }
    });
    return o;
};

function sendAjax(form, method, callback) {
    var url = form.attr('action');
    var form_data = form.serializeObject();
    var data = JSON.stringify(form_data);

    $.ajax({
        url: url,
        type: method,
        contentType: "application/json",
        dataType: 'json',
        data: data

    }).success(function (data) {
        form.find(':submit').removeClass('loading');
        if (data.status == 'success') {
            data.title = 'Concluído';
            //notification(data);
            callback(true, data);
        }

        if (data.status == 'danger') {

            if ('fields' in data) {
                msg_validation(data.fields);
            }
            //showAlert(' Erros de validação: ', 'Corriga os campos com alerta', data.status);
            data.title = 'Ocorreu um erro';
            notification(data);
            callback(false, data)
        }

    });

}

function msg_validation(obj) {
    $.each(obj, function (message, error_element) {
        input_error(error_element, message);
    });
}

function input_error(message, error_element) {
    //var div_error = document.getElementById('msg-error-' + error_element + '');

    var div_error = $('#msg-error-' + error_element);
    var input_name = $(":input[name*=" + error_element + "]");
    var closest_parent = input_name.closest('div[class^="field"]');
    var message_error = $('<div id="msg-error-' + error_element + '" class="ui pointing red basic label transition fade in">' + message + '</div>');

    closest_parent.append(message_error);


    if (div_error.length) {
        div_error.remove();

    } else {

        var div_error = $('#msg-error-' + error_element);

        input_name.closest('div[class^="field"]').addClass('error');

        //closest_parent.on("click", function () {
        //    input_name.closest('div[class^="field"]').removeClass('error');
        //    div_error.remove();
        //
        //});


        input_name.on("focus change click", function () {
            input_name.closest('div[class^="field"]').removeClass('error');
            //div_error.remove();
            $('#msg-error-' + error_element).remove();

        });

    }

}