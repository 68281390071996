/**
 * Created by caiosilva on 29/11/15.
 */
var notifyId = 0;


function notification(data) {

    var type;
    var icon;
    switch (data.status) {
        case 'success':
            type = 'positive';
            icon = 'check';
            break;
        case 'warning':
            type = 'warning';
            icon = 'warning circle';
            break;
        case 'danger':
            type = 'negative';
            icon = 'warning sign'
            break;
        default :
            type = 'info';
            icon = 'info circle';

    }
    notifyId++;
    var html = '';
    html += '<a href="javascript:void(0)" id="notify-'+notifyId+'" class="ui ' + type + ' message icon">';
    html += '   <i class="' + icon + '  transition shake icon"></i>';
    //html += '   <i class="close icon"></i>';
    html += '   <div class="content">';
    html += '       <div class="header">';
    html +=             data.title;
    html += '       </div>';
    html += '       <p>' + data.message + '</p>';
    html += '   </div>';
    html += '</a>';

    $('#notification-message').append(html);
    $('#notify-'+notifyId+'').transition('fly left in');

    autoCloseMessage(notifyId, 10000);
}

var autoCloseMessage = function (msgId, duration) {
    window.setTimeout(function () {
        $('#notify-'+msgId+'').transition('fly left out');
    }, duration);
};

$(document).on('click', '.close', function () {
    $(this).closest('.message').transition('fly left out');
});

$(document).on('click', '[id^=notify-]', function () {
    $(this).closest('.message').transition('fly left out');
});